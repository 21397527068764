import { User } from '@Types/gtm-events/User';
import { Account } from '@Types/account/Account';

export const hydrateUserObj = (account: Account): User | undefined => {
  if (!account) return undefined;
  return {
    user_id: account?.accountId,
    name: account?.firstName + ' ' + account?.lastName,
    first_name: account?.firstName,
    last_name: account?.lastName,
    email: account?.email,
    emailHash: account?.emailHash,
    isTradeAccount: account?.isB2BApproved,
  };
};

export const slugToTitle = (slug: string): string => {
  return slug
    ?.replace(/-/g, ' ')
    ?.replace(/_/g, ' ')
    ?.replace(/\b\w/g, (c) => c.toUpperCase());
};
