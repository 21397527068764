/**
 * Observe elements entering the viewport and trigger the provided callback
 * Reference: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 * @param {String} selector - CSS selector of the elements to observe
 * @param {Function} callback - callback function to trigger when element eneters the viewport
 * @param {Obejct} options - IntersectionObserver options
 */
export const observeElementsInViewport = ({ selector = null, element = null, callback, options = {} }) => {
  const defaultOptions = {
    root: null,
    rootMargin: '0px',
    treshold: 0.5,
  };
  const observerOptions = { ...defaultOptions, ...options };

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        callback(entry.target);
        observer.unobserve(entry.target);
      }
    });
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  const elements = [];
  if (selector) {
    document.querySelectorAll(selector).forEach((elem) => elements.push(elem));
  } else if (element) {
    elements.push(element);
  }

  elements?.forEach((element) => observer.observe(element));

  return {
    unobserve: (target) => {
      observer.unobserve(target);
    },
    disconnect: () => {
      observer.disconnect();
    },
  };
};
