import { gtmEventData } from '@Types/gtm-events/Event';
import { observeElementsInViewport } from '../viewportObserver';
import Router from 'next/router';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const clearDataLayer = [
  'ecommerce',
  'user',
  'cjEvent', // TODO this needs to be refactored to use cjEvents in GTM instead of dataLayer
] as const;

export const gtmTrackEvent = (event: string, data: any, shouldClear?: boolean): void => {
  try {
    shouldClear = shouldClear ?? false;

    const eventObject: Partial<gtmEventData> = {
      event: event,
      _clear: shouldClear,
    };

    // magic sauce - add data object into the event object to record
    Object.assign(eventObject, data);

    // GTM likes to prevent collision with a forced clear on particular objects
    clearDataLayer.forEach((key) => {
      if (eventObject.hasOwnProperty(key)) {
        window?.dataLayer?.push({ [key]: null }); // Clear previous set object
      }
    });

    // push the event to the dataLayer
    window?.dataLayer?.push(eventObject);
  } catch (error) {
    console.error('GTM dataLayer is not available:', event, error);
  }
};

export const gtmTrackEventError = (error: Error, eventName: string) => {
  console.error(`GTM event '${eventName}' failed:`, error);
};

/**
 *
 * gtmDomListener
 * Define the gtmListener function with explicit event typing
 *
 * example: <a href="/categories/cyber-sale" data-gtm-event="click_cta" data-gtm-action="click" data-gtm-event-data='{"click_location":"hero","click_name":"Cyber Sale"}'><img src="sale.jpg" alt="Cyber Sale Today!" /></a>
 *
 * User documentation can be found: https://shadesoflight.atlassian.net/wiki/spaces/PE/pages/78774302/Creating+gtm+event+listeners+via+Amplience
 */
export const gtmDomListener = (event: Event): void => {
  const addEventListeners = (elements: NodeListOf<HTMLElement> | HTMLElement[]) => {
    for (const element of elements) {
      let eventAction = element.dataset?.gtmAction?.toString() ?? 'click';

      // Add event listener based on the action
      switch (eventAction) {
        case 'notify':
          console.log('GTM event occurred', element);
          break;
        case 'scrollIntoView':
          // If we need the gtm actions to trigger only once the element is scrolled into view, we can use data-gtm-action="scrollIntoView"
          if (!element.dataset.listenerBound) {
            element.dataset.listenerBound = 'true'; // Mark as bound
            observeElementsInViewport({
              element: element,
              callback: () => {
                const eventData = JSON.parse(element.dataset?.gtmEventData ?? '{}');
                gtmTrackEvent(element.dataset?.gtmEvent, eventData);
              },
            });
            // gtmEventSecondary will be used to provide a name of the action for when the element is clicked after it has been viewed
            // e.g. gmtEvent = view_promotion, gtmEventSelect = select_promotion
            if (element.dataset?.gtmEventSelect) {
              element.addEventListener('click', () => {
                const eventData = JSON.parse(element.dataset?.gtmEventData ?? '{}');
                gtmTrackEvent(element.dataset?.gtmEventSelect, eventData, false);
              });
            }
          }
          break;
        default:
          // Prevent adding multiple listeners if already bound
          if (!element.dataset.listenerBound) {
            element.dataset.listenerBound = 'true'; // Mark as bound
            element.addEventListener(eventAction, () => {
              const eventData = JSON.parse(element.dataset?.gtmEventData ?? '{}');
              console.log('GTM event occurred', element.dataset);
              gtmTrackEvent(element.dataset?.gtmEvent, eventData, false);
            });
          }
          break;
      }
    }
  };

  // Init listeners on existing elements
  const gtmEvents: NodeListOf<HTMLElement> = document.querySelectorAll('[data-gtm-event]');
  addEventListeners(gtmEvents);

  const observer = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      if (mutation.type === 'attributes' && mutation.target instanceof HTMLElement) {
        const target = mutation.target;

        // Check if the changed attribute is relevant
        if (mutation.attributeName === 'data-gtm-event') {
          console.log(`Attribute ${mutation.attributeName} changed on`, target);

          // Re-bind the event listener if necessary
          const eventAction = target.dataset.gtmAction ?? 'click';
          target.addEventListener(eventAction, () => {
            const eventData = JSON.parse(target.dataset?.gtmEventData ?? '{}');
            console.log('Dynamic GTM event triggered', target.dataset);
            gtmTrackEvent(target.dataset?.gtmEvent, eventData, false);
          });
        }
      }
    }
  });

  // check for element or attr changes
  observer.observe(document.body, {
    childList: true,
    subtree: true,
    attributes: true,
    attributeFilter: ['data-gtm-event', 'data-gtm-action'],
  });
};

// Safely add the `load` event listener in case the trigger isn't explicitly fired
if (typeof window !== 'undefined') {
  window.addEventListener('load', gtmDomListener);
}

// Above will load the listener on initial page load
// But route changes in next js app do not trigger this, so a listener attached to a next/router is needed as well
const setupGtmListenerOnRouteChange = () => {
  // Add event listener for route changes
  Router.events.on('routeChangeComplete', gtmDomListener);

  // Return a cleanup function to remove the event listener
  return () => {
    Router.events.off('routeChangeComplete', gtmDomListener);
  };
};

setupGtmListenerOnRouteChange();
